import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, DatePicker, Row, Col, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../../components/Sidebar/sidebar';
import * as getServices from '../../services/getServices';
import { GoArrowLeft } from "react-icons/go";
import * as uploadServices from '../../services/uploadServices'
import * as updateServices from '../../services/updateServices'
import dayjs from 'dayjs';
import Swal from "sweetalert2";



const { Option } = Select;

const Coupons = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const vendorData = useSelector((state) => state.user.userData);
    const [courses, setCourses] = useState([]);
    const record = location?.state?.couponsData;
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [discountType, setDiscountType] = useState('');
    const [form] = Form.useForm();
    const [disabledOptions, setDisabledOptions] = useState({
        allCourses: false,
        otherCourses: false,
    });
    const [addCoupon, setAddcoupon] = useState(false);

    const handleCourseChange = (selectedCourses) => {
            if (selectedCourses.some(course => course.value === 1)) {
           
                setDisabledOptions({
                    allCourses: false,
                    otherCourses: true,
                });
            } else if (selectedCourses.length > 0) {
            
                setDisabledOptions({
                    allCourses: true,
                    otherCourses: false,
                });
            } else {
            
                setDisabledOptions({
                    allCourses: false,
                    otherCourses: false,
                });
            }
    };


    useEffect(() => {
        if (record?.apply_to_all_courses === 1) {
            setDisabledOptions({
                allCourses: false,
                otherCourses: true,
            });
        } else if (record?.course?.length > 0) {
            setDisabledOptions({
                allCourses: true,
                otherCourses: false,
            });
        } else {
            setDisabledOptions({
                allCourses: false,
                otherCourses: false,
            });
        }
    }, [record]);


    const handleDiscountTypeChange = (value) => {
        setDiscountType(value);
    };
    useEffect(() => {
        let per_page =  `per_page=${-1}`
        dispatch(
            getServices?.getCourseByVendorID(per_page)
        ).then((response) => {

            setCourses(response?.data);
        }).catch((err) => {

            // setLoader(false);
        });
    }, [])

    useEffect(() => {
        if (record) {
            form.setFieldsValue({
                coupon_code: record.coupon_code,
                discount_type: record.discount_type,
                start_date: record.start_date ? dayjs(record.start_date).startOf('day') : null,
                end_date: record.end_date ? dayjs(record.end_date).startOf('day') : null,
                discount_value: record.discount_value,
                min_purchase_amt: record.min_purchase_amt,
                course_id: record.apply_to_all_courses === 1 ? "All Course" : record?.course ? record.course.map(course => ({ value: course.id, label: course.title })) : 'false'
            });
        }
    }, [record, form]);


    const onStartChange = (date, dateString) => {
        setStartDate(dateString)
    };

    const onEndDataChange = (date, dateString) => {
        setEndDate(dateString);
    };

    const onFinish = (values) => {

        if (!record) {
            setAddcoupon(true)
            const selectedCourses = values.course_id;
            let payload = {};
            if (selectedCourses.some(courseId => courseId.value === 1)) {
                payload.apply_to_all_courses = 1;
            } else {
                payload.course_id = selectedCourses.map(courseId => parseInt(courseId.value, 10));
            }
            const data = {
                coupon_code: values.coupon_code,
                discount_type: values.discount_type,
                start_date: startDate,
                end_date: endDate,
                discount_value: values.discount_value,
                min_purchase_amt: values.min_purchase_amt
            }

            if (payload?.course_id !== undefined) {
                data.course_id = JSON.stringify(payload?.course_id)
            }
            if (payload?.apply_to_all_courses !== undefined) {
                data.apply_to_all_courses = payload?.apply_to_all_courses
            }
            dispatch(uploadServices.addNewCoupons(data))
                .then((response) => {
                    if (response) {
                        Swal.fire({
                            title: `${response?.message}`,
                            icon: "Success",

                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/vendor/all-coupons")
                            }
                        })
                        setAddcoupon(false)

                    }
                }).catch((err) => {
                    Swal.fire({
                        title: `${err.response?.data?.message}`,
                        icon: "error",

                    })
                })
        }
        else {

         setAddcoupon(true)
            const selectedCourses = values.course_id;
            let payload = {};
            if (selectedCourses.some(courseId => courseId.value === 1)) {
                payload.apply_to_all_courses = 1;
            } else {
                payload.course_id = selectedCourses.map(courseId => parseInt(courseId.value, 10));
            }
            const data = {
                coupon_code: values.coupon_code,
                discount_type: values.discount_type,
                start_date: startDate ? startDate : record?.start_date,
                end_date: endDate ? endDate : record?.end_date,
                discount_value: values.discount_value,
                min_purchase_amt: values.min_purchase_amt
            }

            if (payload?.course_id !== undefined) {
                data.course_id = JSON.stringify(payload?.course_id)
            }
            if (payload?.apply_to_all_courses !== undefined) {
                data.apply_to_all_courses = payload?.apply_to_all_courses
            }
            dispatch(updateServices.updateCoupons(record?.id, data))
                .then((response) => {
                    if (response) {
                        Swal.fire({
                            title: `${response?.message}`,
                            icon: "Success",

                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/vendor/all-coupons")
                            }
                        })
                        setAddcoupon(false)

                    }
                }).catch((err) => {
                    Swal.fire({
                        title: `${err.response?.data?.message}`,
                        icon: "error",

                    })
                })

        }


    }
    return (
        <Row className="add-course-main-row">
            <Col xl={4} className="d-xl-block d-md-none">
                <Sidebar />
            </Col>
            
            <Col xl={20} md={24}>
                <div className="mainContent add-new-course">
                    <div className="new-course-container">
                        <div className="new-course-inner-container">
                            <div className="header-div">
                                <p className="add-new-course">{record ? "Update Coupon" : "Add New Coupon"}</p>
                                <Button
                                    className="go-back-button"
                                    onClick={() => navigate("/vendor/all-coupons")}
                                >
                                    <span>
                                        <GoArrowLeft className="left-arrow-icon" />
                                    </span>
                                    Go Back
                                </Button>
                            </div>
                            <div className="form-container">
                                <Form
                                    layout="vertical"
                                    // initialValues={coupon}
                                    onFinish={onFinish}
                                    form={form}
                                    className="add-new-course-form"
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Coupon Code"
                                                name="coupon_code"
                                                rules={[{ required: true, message: 'Please input the coupon code!' }]}
                                                placeholder=" Input Coupons Code"
                                            >
                                                <Input className="input-box-login" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>

                                            <Form.Item
                                                label="Discount Type"
                                                name="discount_type"
                                                rules={[{ required: true, message: 'Please select a discount type!' }]}
                                                placeholder=" Input Discount Type"
                                            >
                                                <Select onChange={handleDiscountTypeChange} className="input-box-login">
                                                    <Option value="percentage">Percentage</Option>
                                                    <Option value="fixed_amount">Fixed Amount</Option>
                                                </Select>
                                            </Form.Item>

                                        </Col>

                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Start Date"
                                                name="start_date"
                                                rules={[{ required: true, message: 'Please select a start date!' }]}
                                                placeholder=" Select start  Date"
                                            >
                                                <DatePicker onChange={onStartChange} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="End Date"
                                                name="end_date"
                                                rules={[{ required: true, message: 'Please select an end date!' }]}
                                                placeholder=" Select end  Date"
                                            >
                                                <DatePicker onChange={onEndDataChange} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>

                                            <Form.Item
                                                label="Discount Value"
                                                name="discount_value"
                                                rules={[
                                                    { required: true, message: 'Please input the discount value!' },
                                                    {
                                                        validator: (_, value) => {
                                                            if (discountType === 'percentage' && (value < 0 || value > 100)) {
                                                                return Promise.reject(new Error('Discount value for percentage must be between 0 and 100!'));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input type="number" className="input-box-login" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Select Course"
                                                name="course_id"
                                                rules={[{ required: true, message: 'Please select course' }]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    placeholder="Select Courses"
                                                    labelInValue

                                                    onChange={handleCourseChange}
                                                // className="course-select"
                                                className={disabledOptions.allCourses ? 'course-select' : ''}

                                                >
                                                    <Option value={1} disabled={disabledOptions.allCourses}>All Courses</Option>
                                                    {courses.map(course => (
                                                        <Option key={course.id} value={course.id} disabled={disabledOptions.otherCourses}>
                                                            {course.title}
                                                        </Option>
                                                        
                                                    ))}
                                                   
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}>

                                            <Form.Item
                                                label="Minimum Purchase Amount "
                                                name="min_purchase_amt"
                                                rules={[{ required: true, message: 'Please input the purchase  amount!' }]}
                                                placeholder=" Input Minimum Purchage Amount"
                                            >
                                                <Input type="number" className="input-box-login" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="register-button" disabled={addCoupon}>
                                            {record ? 'Update Coupon' : 'Add Coupon'}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default Coupons;