import React, { useEffect, useState, useContext } from "react";
import {
    Row,
    Col,
    Input,
    Card,
    Pagination,
    Collapse,
    Flex,
    Checkbox,
    Slider,
    Button,
    Drawer,
    Modal,
    InputNumber,
    DatePicker,
    Spin,
    Select
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { MdOutlineCategory } from "react-icons/md";
import { arrowUpRight, featureImg2 } from "../../constant/images";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import { GoHeartFill } from "react-icons/go";
import { LuFilter } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import * as uploadServices from "../../services/uploadServices";
import Swal from "sweetalert2";
import CourseEnquiry from "../courseEnquiry";
// import { add_To_Cart } from "../../store/actions/user";
import { add_To_Cart } from "../../StoreRedux/appSlice";
import { useAtom } from "jotai";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom"
import { CartContext } from "../../Helpers/CartContext"
import { Logo } from "../../constant/images";
import { IoIosArrowRoundBack } from "react-icons/io";
import { DEMO_IMAGE_URL } from "../../Helper"
import Offer from "../Offer/offer"

export default function CourseListing() {
    let { state } = useLocation();
    const userData = useSelector((state) => state.user.userData);
    const categories = useSelector((state) => state.user.categoriesData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItems = useSelector((state) => state.user.cart);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const [current, setCurrent] = useState(1);
    const [courses, setCourses] = useState([]);
    //eslint-disable-next-line
    const [filterCategory, setFilterCategory] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState();
    const [open, setOpen] = useState(false);
    const [distance, setDistance] = useState('');
    const [wishlistData, setWishlistData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [category, setCategory] = useState([])
    const [subCategoryCategory, setSubCategoryCategory] = useState([])
    const [childCategoryCategory, setChildCategoryCategory] = useState([])
    const [sorting, setSorting] = useState('')
    const [order, setOrder] = useState('')
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [price, setPrice] = useState('')
    const [banner, setBanner] = useState([])
    const [loader, setLoader] = useState(true);
    const [offerLoader, setOfferLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [showSearchData, setShowSearchData] = useState([])
    const [cartData, setCartData] = useState([]);
    const [searchString, setSearchString] = useState('')
    const [cartCountValue, setCartCountValue] = useAtom(cartCount)
    const { addItemToCart } = useContext(CartContext);
    const [courseDetails, setCourseDetails] = useState([])
    const [selectedKey, setSelectedKey] = useState([]);
    const [eligibilityFilter, setEligibilityFilter] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [totalCourses, setTotalCourses] = useState(0)
    const locations = useLocation()
    const { Search } = Input;

    const showDrawer = () => {
        setOpen(true);
    };

    const setFalseLoader = () => {
        console.info("GHELOLOLOLo")
        setOfferLoader(false);
    }

    const onClose = () => {
        setOpen(false);
    };
    const typeofCourse = locations?.state?.type

    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState(null);

    const getCourseData = (current) => {
        if (state?.querystring === null || state?.querystring?.includes("paid")) {
            setLoader(true)
            dispatch(getServices.getCourses(state?.querystring ? state?.querystring + `${state?.querystring === "" ? `?per_page=${pageSize}&page=${currentPage}}` : `&per_page=${pageSize}&page=${currentPage}`}` : "" + `?per_page=${pageSize}&page=${currentPage}`)).then((response) => {
                setLoader(false);
                setCourses(response?.data)
                console.log(response?.meta?.total , "courseeeDataaaa")
                setShowSearchData(response?.data)
                setTotalCourses(response?.meta?.total)

            }).catch((err) => {
                setLoader(false);
                console.error({ err });
            })
        }
    }

    useEffect(() => {
        getCourseData(currentPage)
    }, [state?.querystring, pageSize, currentPage])

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    setError(error.message);
                }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };

    const getWishlistData = () => {
        dispatch(getServices.GetWistlistDataUser(userData?.user?.id)).then((response) => {
            setWishlistData(response?.course_details);
            setLoader(false);

        }).catch((error) => {
            setLoader(false);
            console.error({ error });
        })
    }

    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            addItemToCart(response?.data?.length);
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
            setLoader(false);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };

    useEffect(() => {
        getLocation();
        if (isUserLoggedIn) {
            dispatch(
                getServices?.getPaymentDetails(userData?.user?.id)
            )
                .then((response) => {
                    if (response?.payments) {
                        setPaymentDetails(response?.payments);
                    }
                    setLoader(false);

                })
                .catch((err) => {
                    console.error({ err });
                    setLoader(false);

                });
            getWishlistData()
            fetchCartItems()
        }
    }, []);

    useEffect(() => {
        dispatch(getServices?.getBanner('course-listing'))
            .then((result) => {
                setBanner(result?.banner);
                // dispatch(setSettingLogo(result))
            })
            .catch((err) => {
                console.error({ err });
            }).finally(() => {
                setLoader(false);
            })
    }, []);

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(totalCourses);

    const handleWishlistList = (course) => {
        let formData = new FormData();
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadServices.addRemoveCourseWishlist(formData)).then((response) => {
                if (isUserLoggedIn) {
                    getWishlistData()
                }
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add course to wishlist.",
                "error"
            )
        }
    }
    useEffect(() => {
        const categoryIds = courses.map((course) => course.category_id);
        const uniqueCategoryIds = [...new Set(categoryIds)];
        setFilterCategory(uniqueCategoryIds);
    }, [courses]);

    useEffect(() => {
        const categoryIds = courses.map((course) => course.category_id);
        const uniqueCategoryIds = [...new Set(categoryIds)];
        setFilterCategory(uniqueCategoryIds);
    }, [courses]);

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedCourses = courses.slice(startIndex, endIndex);

    const onPageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrent(1);
    }


    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const settingFilterValues = (id, isChecked, subCategories) => {
        setCategory((prev) => {
            const updatedCategories = isChecked
                ? prev.includes(id) ? prev : [...prev, id]
                : prev.filter((item) => item !== id);

            if (isChecked) {
                setSubCategoryCategory((prevSub) => {
                    const newSubCategories = subCategories.map(subCat => subCat.id);
                    return [...new Set([...prevSub, ...newSubCategories])];
                });

                subCategories.forEach(subCat => {
                    const childCategories = subCat.child_category || [];
                    setChildCategoryCategory((prevChild) => {
                        const newChildCategories = childCategories.map(childCat => childCat.id);
                        return [...new Set([...prevChild, ...newChildCategories])];
                    });
                });
            } else {
                setSubCategoryCategory((prevSub) => {
                    const filteredSubCategories = prevSub.filter(subCatID =>
                        !subCategories.some(subCat => subCat.id === subCatID)
                    );
                    return filteredSubCategories;
                });
                setChildCategoryCategory((prevChild) => {
                    return prevChild.filter(childCatID =>
                        !subCategories.some(subCat => childCatID !== subCat.id)
                    );
                });
            }

            return updatedCategories;
        });
    };



    const settingFilterSubCatValues = (subID, isChecked) => {
        setSubCategoryCategory((prev) => {
            let updatedSubCategoryCategory = prev;

            if (isChecked) {
                updatedSubCategoryCategory = prev.includes(subID) ? prev : [...prev, subID];
            } else {
                updatedSubCategoryCategory = prev.filter((item) => item !== subID);
            }

            categories.forEach((parentCategory) => {
                parentCategory.sub_categories.forEach((subCat) => {
                    if (subCat.id === subID && parentCategory.child_category) {
                        const childIDs = parentCategory.child_category?.filter(child => child?.id === subCat?.id)[0]?.child_category?.map((childCat) => childCat?.id);
                        if (isChecked) {
                            setChildCategoryCategory((prevChildCategories) => {
                                const newChildCategories = [...prevChildCategories, ...childIDs];
                                return [...new Set(newChildCategories)];
                            });
                        } else {
                            setChildCategoryCategory((prevChildCategories) =>
                                prevChildCategories.filter((id) => !childIDs.includes(id))
                            );
                            setCategory((prevSub) => {
                                const filterCategories = prevSub.filter(catId => catId !== subCat?.parent_id
                                );
                                return filterCategories;
                            });
                        }
                    }
                });
                if (parentCategory.sub_categories.some((subCat) => subCat.id === subID)) {
                    const allSelected = parentCategory.sub_categories.every((subCat) =>
                        updatedSubCategoryCategory.includes(subCat.id)
                    );

                    if (!allSelected) {
                        setCategory((prevCategories) => prevCategories.filter((cat) => cat !== parentCategory.id));
                    } else if (isChecked) {
                        setCategory((prevCategories) =>
                            prevCategories.includes(parentCategory.id) ? prevCategories : [...prevCategories, parentCategory.id]
                        );
                    }
                }
            });
            return updatedSubCategoryCategory;
        });
    };


    const settingFilterChildCatValues = (childId, isChecked, subCatID, parentCatID) => {
        setChildCategoryCategory((prev) => {
            if (isChecked) {
                setCategory((prevCategories) =>
                    prevCategories.includes(parentCatID) ? prevCategories : [...prevCategories, parentCatID]
                );
                setSubCategoryCategory((prevCategories) =>
                    prevCategories.includes(subCatID) ? prevCategories : [...prevCategories, subCatID]
                );
                return [...prev, childId];

            } else {
                setCategory((prevCategories) => prevCategories.filter((cat) => cat !== parentCatID));
                setSubCategoryCategory((prevCategories) => prevCategories.filter((cat) => cat !== subCatID));
                return prev.filter(id => id !== childId);
            }
        });


    };

    useEffect(() => {
        setShowSearchData(state?.cartegoryPageData)
        setCategory([])
        setSubCategoryCategory([])
        if (state?.categoryArray?.includes("parent_category")) {
            const queryString = state?.categoryArray;
            const params = new URLSearchParams(queryString);
            const parentCategoryID = params?.get('parent_category')?.replace(/[\[\]]/g, '');
            const parentCategory = categories.find(cat => cat?.id === parseInt(parentCategoryID));
            if (parentCategory) {
                settingFilterValues(parentCategory?.id, true, parentCategory?.child_category);
            }
        } else if (state?.categoryArray?.includes("sub_category")) {
            const queryString = state?.categoryArray;
            const params = new URLSearchParams(queryString);
            const subCategoryID = params.get('sub_category')?.replace(/[\[\]]/g, '');
            settingFilterSubCatValues(parseInt(subCategoryID), true);
        } else if (state?.searchString) {
            setSearchString(state?.searchString)
        }
    }, [state]);

    const fetchData = async () => {
        setLoader(true)
        let data = {};

        if (searchString !== '') {
            data.search = searchString
        }
        if (selectedKey) {
            data.approach = JSON.stringify(selectedKey)
        }

        if (category.length > 0) {
            data.parent_category = JSON.stringify(category);
        }
        if (subCategoryCategory.length > 0) {
            data.sub_category = JSON.stringify(subCategoryCategory);
        }

        if (childCategoryCategory.length > 0) {
            data.child_category = JSON.stringify(childCategoryCategory);
        }

        if (sorting && order) {
            data.sort_by = sorting;
            data.sort_order = order;
        }

        if (minAge) {
            data.min_age = minAge;
        }
        if (maxAge) {
            data.max_age = maxAge;
        }
        if (startDate) {
            data.start_date = startDate;
        }
        if (endDate) {
            data.end_date = endDate;
        }
        if (distance) {
            data.longitude = location?.longitude
            data.latitude = location?.latitude
            data.distance = distance
        }
        if (price) {
            data.max_fee = price?.maxPrice
            data.min_fee = price?.minPrice
        }
        if (eligibilityFilter.includes("WSQ")) {
            data.course_status = 1
        }

        if (eligibilityFilter.includes("SFC")) {
            data.is_sfc_status = 1
        }
        try {

            let quesryString = state?.querystring === "" || state?.querystring === undefined || state?.querystring === null ? `?` + new URLSearchParams(data).toString() : state?.querystring + `&` + new URLSearchParams(data).toString()
            if (data.approach) {
                quesryString = quesryString.replace(/approach=[^&]*/, `approach=${data.approach}`);
            }

            await dispatch(getServices.getCourses(quesryString))
                .then((response) => {
                    setLoader(false)
                    if (response?.data) {
                        console.log(response?.data)
                        setShowSearchData(response?.data);
                        setCourses(response?.data)
                    }
                    else {
                        setLoader(false)
                        Swal.fire({
                            title: response?.message,
                            icon: "error",
                        })
                    }
                }).catch((error) => {
                    setLoader(false)
                    Swal.fire({
                        title: error?.response?.data?.message,
                        icon: "error",
                    })
                })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleSort = (sort_by, sort_order) => {
        setOrder(sort_order)
        setSorting(sort_by)
    }

    const handleOrderChange = (e) => {
        setOrder(e.target.value);
    };

    const priceFilter = (min_max_price) => {
        setPrice(min_max_price)
    };

    const handleMinAgeChange = (maxage) => {
        setMinAge(maxage);
    };

    const handleMaxAgeChange = (minage) => {

        setMaxAge(minage);
    };

    const onStartChange = (date, dateString) => {
        setStartDate(dateString)
    };

    const onEndDataChange = (date, dateString) => {
        setEndDate(dateString);
    };

    const applyFilters = () => {
        if (searchString == '') {
            Swal.fire({
                title: "Provide  search term",
                icon: "info",
                allowEnterKey: false,
            });
            setShowSearchData(courses)
        } else {
            fetchData();
        }
    };

    const sort = [
        { label: '1', value: 'Price (Low to High)', sort_by: 'fee', sort_order: 'asc' },
        { label: '2', value: 'Price  (High to Low)', sort_by: 'fee', sort_order: 'desc' },
        { label: '3', value: 'Minimum Age(Low to High)', sort_by: 'min_age', sort_order: 'asc' },
        { label: '4', value: 'Minimum Age(High to Low)', sort_by: 'min_age', sort_order: 'desc' },
        { label: '5', value: 'Maximum Age(Low to High)', sort_by: 'max_age', sort_order: 'asc' },
        { label: '6', value: 'Maximum Age(High to Low)', sort_by: 'max_age', sort_order: 'desc' },
        { label: '7', value: 'Newest course', sort_by: 'created_at', sort_order: 'asc' },
        { label: '8', value: 'Oldest course', sort_by: 'created_at', sort_order: 'desc' }
    ];

    const checkChildCategory = (item) => {
        if (childCategoryCategory?.length > 0) {
            return childCategoryCategory.includes(item);
        }
        return false;
    };

    const checkSubCategory = (item) => {
        if (subCategoryCategory?.length > 0) {
            return subCategoryCategory.includes(item);
        }
        return false;
    };



    const checkedMainCategory = (item) => {
        if (category?.length > 0) {
            return category.includes(item);
        }
    }

    const handleAddToCart = (data, text) => {

        if (!isUserLoggedIn && !isVendorLoggedIn) {
            if (data?.is_premium === "free" || data?.fee === 0 || data?.fee === null) {
                data.fee = 0;
            }

            dispatch(add_To_Cart(data))
            addItemToCart(cartItems?.length + 1);
        } else {
            const addToCart = (data) => {
                const formData = new FormData()
                formData.append("course_id", data?.id)
                formData.append("course_price", data?.fee === 0 || data?.is_premium === "free" || data?.fee === null ? 0 : data?.fee)
                dispatch(uploadServices.addToCart(formData)).then((response) => {
                    if (isUserLoggedIn) {
                        fetchCartItems()
                        if (text === "purchase") {
                            navigate("/checkout")
                        }
                    }
                    Swal.fire(
                        "Success",
                        `${response.message}`,
                        "success"
                    )
                }).catch((error) => {
                    Swal.fire("Failed", error.message);
                })
            }
            addToCart(data)
        }
    }
    const options = [
        { label: 'Online', value: 1 },
        { label: 'Physical', value: 2 },
        { label: 'Combined', value: 3 },
    ];

    const handleApproachChange = (value) => {
        if (selectedKey.includes(value)) {
            setSelectedKey(selectedKey.filter(key => key !== value));
        } else {
            setSelectedKey([...selectedKey, value]);
        }
    };

    const plainOptions = ['WSQ', 'SFC'];

    const onChnageCheckbox = (checkedValues) => {
        setEligibilityFilter(checkedValues)
    };


    const items = [
        {
            key: "1",
            label: "Category",
            children: (
                <>
                    {categories &&
                        categories.map((item) => (
                            <div key={item?.id} className="mb-3">
                                <div className="d-flex align-items-center mb-2">
                                    <Checkbox
                                        value={item?.id}
                                        checked={checkedMainCategory(item?.id)}
                                        onChange={(e) => {
                                            settingFilterValues(item?.id, e.target.checked, item.child_category);
                                        }}
                                        style={{ paddingRight: "10px" }}
                                    />
                                    <p style={{ marginBottom: 0 }}>{item?.name}</p>
                                </div>
                                <div className="px-4">
                                    {item?.sub_categories?.map((subCat) => (
                                        <>
                                            <div key={subCat?.id} className="d-flex align-items-center mb-2">
                                                <Checkbox
                                                    value={subCat?.id}
                                                    checked={checkSubCategory(subCat?.id)}
                                                    onChange={(e) => {
                                                        settingFilterSubCatValues(subCat?.id, e.target.checked);
                                                    }}
                                                    style={{ paddingRight: "10px" }}
                                                />
                                                <p style={{ marginBottom: 0 }}>{subCat?.name}</p>
                                            </div>
                                            <div className="px-4">
                                                {item?.child_category?.filter(child => child?.id === subCat?.id).map((child) =>
                                                    child?.child_category?.map((childCategory) => (
                                                        <div key={childCategory?.id} className="d-flex align-items-center mb-2">
                                                            <Checkbox
                                                                value={childCategory?.id}
                                                                checked={checkChildCategory(childCategory?.id)}
                                                                onChange={(e) => {
                                                                    settingFilterChildCatValues(childCategory?.id, e.target.checked, subCat?.id, item?.id);
                                                                }}
                                                                style={{ paddingRight: "10px" }}
                                                            />
                                                            <p style={{ marginBottom: 0 }}>{childCategory?.name}</p>
                                                        </div>
                                                    ))
                                                )}

                                            </div>
                                        </>
                                    ))}

                                </div>
                            </div>
                        ))
                    }
                </>
            ),
        },
        {
            key: "2",
            label: "Sort",
            children: (
                <>
                    <Select
                        className="input-box-login sub-select-category"
                        placeholder="Please Select"
                        prefix={<MdOutlineCategory />}
                        onChange={(value) => {
                            const selectedSort = sort.find(sorting => sorting.label === value);
                            if (selectedSort) {
                                 handleSort(selectedSort.sort_by, selectedSort.sort_order);
                            }
                        }}
                    >
                        {sort &&
                            sort.map((sorting) => (
                                <Select.Option
                                    key={sorting.label}
                                    value={sorting.label}

                                >
                                    {sorting.value}
                                </Select.Option>
                            ))}
                    </Select>
                </>
            )
        },

        {
            key: "3",
            label: "Price",
            children: (
                <>
                    <Slider range defaultValue={[0, 20]} onChange={(e) => priceFilter({ minPrice: e[0], maxPrice: e[1] })} />
                    <div className="slider-content-container">
                        <p className="slider-des-text">0k</p>
                        <p className="slider-des-text">50k</p>
                    </div>
                </>
            ),
        },
        {
            key: "4",
            label: "Distance",
            children: (
                <>
                    <Slider className="distance-slider" onChange={(e) => setDistance(e)} defaultValue={0} />
                    <div className="slider-content-container">
                        <p className="slider-des-text">5km</p>
                        <p className="slider-des-text">70km</p>
                    </div>
                </>
            ),
        },
        {
            key: "5",
            label: "Age",
            children: (
                <>
                    <div className="row d-flex flex-wrap justify-between">


                        <label className="age-label col-6">
                            <span className="d-block w-100 mb-1">
                                <b> Min Age:</b></span>

                            <InputNumber className="w-100" onChange={handleMinAgeChange} />

                        </label>
                        <label className="age-label col-6">
                            <span className="d-block w-100 mb-1">
                                <b>Max Age:</b></span>
                            <InputNumber className="w-100" onChange={handleMaxAgeChange} />

                        </label>
                    </div>
                </>


            )

        },
        {
            key: "6",
            label: "Date",
            children:
                (
                    <>
                        <div className="row d-flex flex-wrap justify-between">
                            <label className="date-label col-6">
                                <b>Start Date:</b>

                                <DatePicker className="w-100" onChange={onStartChange} />

                            </label>
                            <label className="date-label col-6">
                                <b>End Date:</b>
                                <DatePicker className="w-100" onChange={onEndDataChange} />

                            </label>
                        </div>
                    </>

                )
        },
        {
            key: "7",
            label: "Approach",
            children:
                (
                    <>
                        <div className="row d-flex flex-wrap justify-between">
                            {options.map(option => (
                                <Checkbox
                                    key={option.value}
                                    checked={selectedKey.includes(option.value)}
                                    onChange={() => handleApproachChange(option.value)}
                                >
                                    {option.label}
                                </Checkbox>
                            ))}
                        </div>
                    </>

                )
        },
        {
            key: "8",
            label: "Eligibility",
            children:
                (
                    <>
                        <div className="row d-flex flex-wrap justify-between eligibility-filter-container">
                            <Checkbox.Group className="eligibility-check-group" options={plainOptions} onChange={onChnageCheckbox} />
                        </div>
                    </>

                )
        },
    ];






    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };

    // const handlePurchase = (coursedata) => {
    //     if (isUserLoggedIn || isVendorLoggedIn) {
    //         navigate(`/payment/${userData?.user?.id}/${coursedata?.id}`, {
    //             state: { courseData: coursedata },
    //         });
    //     } else {
    //         navigate("/login");
    //     }
    // };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleCourseEnquiry = (course) => {

        if (isUserLoggedIn || isVendorLoggedIn) {
            setCourseDetails([{ course_id: course?.id, vendor_id: course?.vendor?.id }])
            setModalOpen(true);
        } else {
            navigate("/login")
        }
    }
    // const totalItem = (showSearchData && showSearchData.length > 0) ? showSearchData.length : courses?.length;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems =
    //     showSearchData?.length > 0
    //         ? showSearchData?.slice(indexOfFirstItem, indexOfLastItem)
    //         : courses?.slice(indexOfFirstItem, indexOfLastItem)


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleBack = () => {
        navigate("/");
    }

    return (
        <>
            {loader || offerLoader ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 9999
                }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 28, color: "#fff" }} spin />} />
                </div>
            ) : null}

            <div className="course-listing-container">
                {
                    banner ? (
                        <>
                            <div className="course-listing-banner-container" style={{ backgroundImage: `url(${DEMO_IMAGE_URL}${banner?.image})` }} key={banner?.id}>

                                <Button className="back-to-button-course-listing back-to-button" onClick={handleBack}><IoIosArrowRoundBack />Back to home</Button>
                                <div className="container">
                                    <p className="course-listing-banner-sub-heading">
                                        {banner?.title}
                                    </p>
                                    <h2 className="course-listing-banner-heading">{banner?.sub_title}</h2>
                                </div>

                            </div>
                        </>
                    ) : (
                        <div className="blog-banner-container">
                            <div className="blog-cover-text">
                                <h2 className="blog-cover-text-heading">Browse All Courses</h2>
                                <p className="blog-cover-text-subhead">
                                    Explore Our Comprehensive Course Catalogue
                                </p>
                            </div>
                        </div>
                    )
                }
                <Offer setFalseLoader={setFalseLoader} />
                <Row className="courses-with-filter-container customRowComponent">
                    <Col className="course-list-child-one px-4 py-4 pe-5" xl={18} md={24} sm={24}>
                        <div className="course-list-child-one-head d-flex justify-content-between align-items-center mobile-none">
                            <p className="border-bottom-heading bottom-border-left ">
                                {
                                    typeofCourse === 1
                                        ? 'Free/Trial Items'
                                        : 'All Items'
                                }
                            </p>
                            <div className="right-div d-flex flex-wrap justify-content-end">
                                <Button className="d-xl-none filters-button w-100" type="button" onClick={showDrawer}>
                                    <LuFilter />Filters
                                </Button>

                                <Drawer title="Filters" onClose={onClose} open={open}>
                                    <Row className="courses-with-filter-container-Drawer">
                                        <Col className="course-list-child-two" xl={6} md={24}>


                                            <div className="course-filter-container">
                                                <div className="course-filter-head">
                                                    <p className="course-filter-heading">All Filters</p>
                                                    <Button className="course-filter-count">Apply</Button>
                                                </div>

                                                <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                                                    {items?.map((item) => (
                                                        <Panel
                                                            header={
                                                                <div className="panel-header" key={item?.checkChildCategory}>
                                                                    <span className="panel-label">{item.label}</span>
                                                                </div>
                                                            }
                                                            key={item.key}
                                                        >
                                                            <p className="panel-description">{item.children}</p>
                                                        </Panel>
                                                    ))}
                                                </Collapse>
                                            </div>
                                        </Col>
                                    </Row>
                                </Drawer>
                                <Search className="search-box" onChange={(e) => setSearchString(e.target.value)} placeholder="Search" onSearch={applyFilters} enterButton allowClear />
                            </div>
                        </div>
                        {/* {loader ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />} />
                            </div>
                        ) : ( */}
                        {showSearchData?.length > 0 ? (
                            <>
                                <Row className="">
                                    {showSearchData?.length > 0 &&
                                        showSearchData?.map((course, index) => {

                                            return (
                                                <Col key={course?.id || index}
                                                    className={(index + 1) % 2 === 0 ? "ps-3 mb-4 mt-4" : "pe-3 mb-4 mt-4"}
                                                    style={{ padding: "0 0.5rem" }}
                                                    md={12}
                                                >
                                                    <Row className="course_cardinfo_top">
                                                        <Col md={12}>
                                                            {course?.parent_category_name !== null ? <h5 className="main-category mb-0">{course?.parent_category_name}</h5 > : ''
                                                            }
                                                        </Col>
                                                        <Col md={12} className="text-end">
                                                            {
                                                                course?.approach !== 0 ? <h5 className="course-approach mb-0">{course?.approach === 1 ? "Online" : course?.approach === 2 ? "Physical" : "Combined"}</h5 > : ''
                                                            }
                                                        </Col>
                                                    </Row>

                                                    <Card
                                                        className="feature-card"
                                                        hoverable
                                                        cover={
                                                            <img
                                                                className={course?.approach !== 0 ? "image-radius" : ""}
                                                                onClick={(e) => handleCourseData(course)}
                                                                style={{ height: "250px" }}
                                                                src={course?.image ? `${DEMO_IMAGE_URL}${course?.image}` : featureImg2}
                                                                alt="text"
                                                            />
                                                        }
                                                    >
                                                        <div className="wishlist-icon-div" >
                                                            {
                                                                isUserLoggedIn && course?.payment_status !== "completed" ? (
                                                                    <button
                                                                        type="button"
                                                                        className="btn wishlist-feature-button add-to-wishlist"
                                                                        onClick={() => handleWishlistList(course)}
                                                                    >
                                                                        {wishlistData?.some(
                                                                            (wish) => parseInt(wish?.id) === parseInt(course?.id)
                                                                        ) ? (
                                                                            <GoHeartFill />
                                                                        ) : (
                                                                            <FaRegHeart />
                                                                        )}
                                                                    </button>
                                                                ) : " "
                                                            }

                                                        </div>
                                                        <div>
                                                            <div className="card-position-div">
                                                                <div
                                                                    style={{
                                                                        zIndex: "99",
                                                                        padding: "10px 15px",
                                                                        backgroundColor: "#106ab3",
                                                                        borderRadius: "0 10px 10px 0",
                                                                    }}
                                                                    className="expire-container"
                                                                >
                                                                    {course?.payment_status === "completed" ? (
                                                                        <span className="Lock-icon">
                                                                            <FaUnlock color="#fff" />
                                                                        </span>
                                                                    ) : (
                                                                        <span className="Lock-icon">
                                                                            <FaLock color="#fff" />
                                                                        </span>
                                                                    )}
                                                                </div>

                                                                {course?.course_start && course?.course_end ? (
                                                                    <div
                                                                        style={{
                                                                            zIndex: "99",
                                                                            padding: "10px 10px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "10px 0 0 10px",
                                                                        }}
                                                                        className="expire-container course-expire-wrap"
                                                                    >
                                                                        <div className="start-end-date-container">
                                                                            <p style={{ color: "#fff", margin: 0 }} className="start-enddate">
                                                                                {DateFormatter(course?.course_start)} - {DateFormatter(course?.course_end)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : course?.course_start && !course?.course_end ? (
                                                                    <div
                                                                        style={{
                                                                            zIndex: "99",
                                                                            padding: "10px 10px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "10px 0 0 10px",
                                                                        }}
                                                                        className="expire-container course-expire-wrap"
                                                                    >
                                                                        <div className="start-end-date-container">
                                                                            <p style={{ color: "#fff", margin: 0 }} className="start-enddate">
                                                                                Start date - {DateFormatter(course?.course_start)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : !course?.course_start && course?.course_end ? (
                                                                    <div
                                                                        style={{
                                                                            zIndex: "99",
                                                                            padding: "10px 10px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "10px 0 0 10px",
                                                                        }}
                                                                        className="expire-container course-expire-wrap"
                                                                    >
                                                                        <div className="start-end-date-container">
                                                                            <p style={{ color: "#fff", margin: 0 }} className="start-enddate">
                                                                                End date - {DateFormatter(course?.course_end)}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                ) : (<></>)}
                                                            </div>
                                                            <div style={{ cursor: "pointer" }} onClick={(e) => handleCourseData(course)}>
                                                                <div style={{ cursor: "pointer" }}>
                                                                    <Flex gap="middle" className="feature-course-subhead-price">

                                                                        <div>
                                                                            {
                                                                                course?.is_premium == "paid" ? (
                                                                                    <p className="wof-certified-logo"><span><img style={{ width: "20px", height: "20px" }} src={Logo} /></span>WOF Certified</p>
                                                                                ) : ''
                                                                            }

                                                                            <p
                                                                                style={{
                                                                                    color: "#106AB3",
                                                                                    fontWeight: 700,
                                                                                }}
                                                                            >
                                                                                {Category(course?.category_id)}
                                                                            </p>
                                                                        </div>
                                                                        <div className="price_card_div">
                                                                            <p
                                                                                style={{
                                                                                    color: "#1EAA39",
                                                                                    fontWeight: 700,
                                                                                    marginBottom:0,
                                                                                }}
                                                                            >
                                                                                {course?.fee !== 0 && course?.fee !== null ? `$${course.fee}` : 'Free'}
                                                                            </p>
                                                                            {
                                                                                course?.course_status === 1 && course?.is_sfc_status === 1 ? (


                                                                                    <span className="wfc_sfc_tag">
                                                                                        {course?.course_status === 1 && course?.is_sfc_status === 1
                                                                                            ? "WSQ, SFC"
                                                                                            : course?.course_status === 1
                                                                                                ? "WSQ"
                                                                                                : course?.is_sfc_status === 1
                                                                                                    ? "SFC"
                                                                                                    : ''
                                                                                        }
                                                                                    </span>


                                                                                ) : ""
                                                                            }
                                                                          
                                                                        </div>
                                                                    </Flex>
                                                                    <Flex className="feature-course-subhead-price">
                                                                        <h4>{course?.title}</h4>
                                                                        <img
                                                                            style={{ width: "25px", height: "auto" }}
                                                                            src={arrowUpRight}
                                                                            alt="text"
                                                                        />
                                                                    </Flex>
                                                                </div>
                                                                {/* <Row>
                                                                        <Col span={8}>
                                                                           {course?.approach === 1 ? <Button>Online</Button> : course?.approach === 2 ? <Button>Physical</Button> : course?.approach === 3 ? <Button>Combined</Button> : ""}
                                                                        </Col>
                                                                    </Row> */}
                                                                <div>
                                                                    <p className="course-description">
                                                                        {course?.about_course !== 'undefined' ? course?.about_course : ''}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <Flex className="feature-course-subhead-price">
                                                            {course?.payment_status === "completed" || userData?.vendor?.id === course?.vendor_id ? (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn feature-button view-course-button"
                                                                        onClick={(e) => handleCourseData(course)}
                                                                    >
                                                                        View Items
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>

                                                                    {
                                                                        isUserLoggedIn ? (
                                                                            <>
                                                                                {cartData && cartData.some(cartItem => cartItem?.course?.id === course?.id) ? (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn add-to-cart-button"
                                                                                        onClick={() => navigate("/cart")}
                                                                                    >
                                                                                        Go to cart
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn add-to-cart-button"
                                                                                        onClick={() => handleAddToCart(course)}
                                                                                    >
                                                                                        Add to cart
                                                                                    </button>
                                                                                )}

                                                                                {isUserLoggedIn ? (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn enroll-button"
                                                                                        onClick={() => handleAddToCart(course, "purchase")}
                                                                                    >
                                                                                        Purchase
                                                                                    </button>
                                                                                ) : (<></>)}
                                                                            </>

                                                                        ) : (
                                                                            <></>

                                                                        )
                                                                    }

                                                                    {!isVendorLoggedIn && !isUserLoggedIn ? (
                                                                        <>
                                                                            {cartItems && cartItems.some(cartItem => cartItem?.id === course?.id) ? (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn enroll-button"
                                                                                    onClick={() => navigate("/cart")}
                                                                                >
                                                                                    Go to cart
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn enroll-button"
                                                                                    onClick={() => handleAddToCart(course)}
                                                                                >
                                                                                    Add to cart
                                                                                </button>
                                                                            )}
                                                                        </>

                                                                    ) : (
                                                                        <></>

                                                                    )
                                                                    }

                                                                </>
                                                            )}
                                                            {userData?.vendor?.id === course?.vendor_id ? (<></>) : (
                                                                <button
                                                                    type="button"
                                                                    className="btn add-to-cart-button"
                                                                    onClick={() => handleCourseEnquiry(course)}
                                                                >
                                                                    Enquiry Now
                                                                </button>
                                                            )}

                                                        </Flex>
                                                    </Card>
                                                </Col>
                                            );


                                        })}
                                </Row>
                                <Pagination
                                    className="mt-4"
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={totalCourses}
                                    onChange={handlePageChange}
                                    onShowSizeChange={onPageSizeChange}
                                    showSizeChanger
                                    pageSizeOptions={pageSizeOptions}
                                />
                            </>
                        ) : (
                            <p className="no_items_found">No Items found.</p>
                        )
                        }

                    </Col>
                    <Col className="course-list-child-two mobile-none" xl={6} md={24}>


                        <div className="course-filter-container">
                            <div className="course-filter-head">
                                <p className="course-filter-heading">All Filters</p>
                                {/* <p className="course-filter-count">Applied (0)</p> */}
                                <Button className="course-filter-count" onClick={fetchData}>Apply</Button>
                            </div>

                            <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                                {items?.map((item) => (
                                    <Panel
                                        header={
                                            <div className="panel-header" key={item?.key}>
                                                <span className="panel-label">{item.label}</span>
                                            </div>
                                        }
                                        key={item.key}
                                    >
                                        <p className="panel-description">{item.children}</p>
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>


                    </Col>
                </Row>
            </div >
            <Modal
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                className="course-enquiry-modal"
            >
                <CourseEnquiry course_details={courseDetails} setModalOpen={setModalOpen} />
            </Modal>

        </>
    );
}
