import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as getServices from "../../services/getServices";
import { DEMO_IMAGE_URL } from "../../Helper";

const CMS = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    if (slug) {
      dispatch(getServices?.getPageDetails(slug))
        .then((response) => {
          setPageData(response?.page); 
        })
        .catch((err) => {
          console.error("Error fetching page data:", err);
        });
    }
  }, [slug, dispatch]);

  if (!pageData) {
    return <div>Loading...</div>; 
  }

  console.log(pageData, "");

  return (
    <div className="blog-container">
      <div
        className="blog-banner-container"
        style={{
          backgroundImage: `url(${pageData?.image ? `${DEMO_IMAGE_URL}${pageData?.image}` : "/assets/images/blog-banner-image.png"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="blog-cover-text">
          <h2 className="blog-cover-text-heading">{pageData?.title}</h2>
          <p className="blog-cover-text-subhead">{pageData?.summary}</p>
        </div>
      </div>
      <div className="blogs-tab-container">
        <div dangerouslySetInnerHTML={{ __html: pageData?.description }}></div>
      </div>
    </div>
  );
};

export default CMS;
